import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import $ from 'jquery';

function Header() {
	const [t, i18n] = useTranslation()

	const setSubMenu = (item) => {
		let visibleItem = false
		if(item) {
			if(item.charAt() === '#') {
				if(item === '#webtoon'){
					$('body').css('overflow', 'hidden')
					$('.content').hide()
					$('#webtoon').css('display', 'flex')
				} else {
					$('body').css('overflow', 'unset')
					$('.content').show()
					$('#webtoon').hide()
				}
				$("html, body").animate({scrollTop: ($((item).toString()).offset()).top}, 500) 
			} else {
				if( (/^[a-z]/i).test(item) ) item = '#' + item + "SubMenu"
				if($(item).is(":hidden")) visibleItem = true
			}
		}
		$('.subMenu').hide()

		if(item && visibleItem) $(item).css('display', 'flex')
		else if(parseInt($('body').width()) <= 768) $('.menu').hide()
	}

	useEffect(() => {
		if(localStorage.lang) i18n.changeLanguage(localStorage.lang)
		switch (localStorage.lang) {
			case 'ko':
				$('#settenLang').text('한국어')
				break;
			case 'zh_s':
				$('#settenLang').text('简体字')
				break;
			case 'zh_t':
				$('#settenLang').text('繁體字')
				break;
			case 'vi':
				$('#settenLang').text('Tiếng Việt')
				break;
			default:
				$('#settenLang').text('English')
				break;
		}
    },[])

	return (
		<>
			<div id="header">
				<div className="headerTab">
					<a id='moblieButton' onClick={() => setSubMenu('.menu')} >
						<img className='logoText' src={require('../img/mobileMenu.png')} />
					</a>
					<a onClick={() => setSubMenu('#root')} className="logo">
						<img className='logoIcon' src={require('../img/logo.png')} />
						<img className='logoText' src={require('../img/logo_text.png')} />
					</a>
					
					<div className="menu bold7">
						<a onClick={() => setSubMenu()} href='https://timestope.mojaik.com' target={"_blank"}>Timestope</a>
						<hr />
						<a onClick={() => setSubMenu('#economy')}>{t('menu_Economy')}</a>
						<hr />
						<a onClick={() => setSubMenu('#product')}>{t('menu_Product')}</a>
						<hr />
						<a onClick={() => setSubMenu('#team')}>Team</a>
						<hr />
						<a onClick={() => setSubMenu('#baduk')}>{t('part_MT')}</a>
						<hr />
						{/* <a onClick={() => setSubMenu('#roadMap')}>{t('menu_Roadmap')}</a> */}
						{/* <hr /> */}
						{/* <a onClick={() => setSubMenu('#purchase')}>{t('menu_Buy')}</a> */}
						{/* <hr /> */}
						{/* <a href=''>News</a> */}
						<a onClick={() => setSubMenu('#download')}>Download</a>
						<hr />
						<div onClick={() => setSubMenu("economy")}>{t('menu_Explorer')}
							<div id="economySubMenu" className='subMenu'>
								<a onClick={() => setSubMenu()} href='https://exp.mojaik.com' target={"_blank"}>Explorer</a>
								<a onClick={() => setSubMenu()} href='https://github.com/Timestopeofficial/feechain' target={"_blank"}>Github(Node)</a>
								{/* <br /> */}
								{/* <a onClick={() => setSubMenu()} href='https://staking.mojaik.com' target={"_blank"}>Staking</a> */}
								{/* <a onClick={() => setSubMenu()} href='' target={"_blank"}>Swap</a> */}
							</div>
						</div>
						{/* <a onClick={() => setSubMenu()} href='https://explorer.mojaik.com/' target={"_blank"}>{t('menu_Explorer')}</a> */}
						<hr />

						<div onClick={() => setSubMenu('webtoon')} className='webtoonText'>
							<img src={require('../img/webtoon_icon.png')} />
							<a onClick={() => setSubMenu('#webtoon')} className='webtoonText'>Webtoon</a>
							{/* <a onClick={() => setSubMenu()} href={'https://m.blog.naver.com/saribly/'+( localStorage.lang === 'ko' ? '222896479461' : '222896616506' )} target={"_blank"}>{t('menu_Webtoon')}</a> */}
						</div>



						<div onClick={() => setSubMenu('lang')} className='langList'>
							<img src={require('../img/lang.png')} />
							<p id="settenLang">English</p>
							<img className='langDropButton' src={require('../img/langDrop.png')} />
							<div id="langSubMenu" className='subMenu'>
								<a href='/en'>English</a>
								<hr />
								<a href='/ko'>한국어</a>
								<hr />
								<a href='/zh_s'>简体字</a>
								<hr />
								<a href='/zh_t'>繁體字</a>
								<hr />
								<a href='/vi'>Tiếng Việt</a>
							</div>
						</div>
					</div>
				</div>

			</div>
		</>
	);
}

export default Header;