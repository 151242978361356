import Baduk from './baduk';
import Basick from './basick';
import Ceo from './ceo';
import Timestope from './timestope';

const Activity = () => {
	return (
        <>
		<Baduk />
        <Basick />
        {/* <Ceo /> */}
        <Timestope />
        </>
	);
};

export default Activity;