import { useTranslation, Trans } from 'react-i18next'

const Product = () => {
    const [t] = useTranslation();
    return (
        <>
            <div id='product' className='content'>
                <div className='bgImgContaner'>
                    <img src={require('../../img/brandBg.png')} loading="lazy"/>
                
                </div>
                <p className='fsMT'>{t('product_Title')}</p>
                <div className='brandContentList'>
                    <div className='brandContentItem'>
                        <img className='brandLogo' src={require('../../img/blood_logo.png')} />
                        <p><Trans>product_Detail1</Trans>
                        </p>
                    </div>
                    <div className='brandContentItem'>
                        <img className='brandLogo' src={require('../../img/timestope_logo.png')} />
                        <p><Trans>product_Detail2</Trans>
                        <a href='https://timestope.mojaik.com' target={"_blank"}>timestope.com</a>
                        </p>
                    </div>
                    <div className='brandContentItem'>
                        <img className='brandLogo' src={require('../../img/fee_logo.png')} />
                        <p><Trans>product_Detail3</Trans>
                        <a href='https://explorer.timestope.com/' target={"_blank"}>explorer.timestope.com</a>
                        </p>
                    </div>
                    {/* <div className='brandContentItem'>
                        <img className='brandLogo' src={require('../../img/citywalk_logo.png')} />
                        <p><Trans>product_Detail4</Trans>
                        <a href='https://citywalk.run' target={"_blank"}>citywalk.run</a>
                        </p>
                    </div> */}
                    {/* <div className='brandContentItem'>
                        <img className='brandLogo' src={require('../../img/nft_logo.png')} />
                        <p><Trans>product_Detail5</Trans>
                        </p>
                    </div> */}
                    {/* <div className='brandContentItem'>
                        <img className='brandLogo' src={require('../../img/sellmetime_logo.png')} />
                        <p><Trans>product_Detail6</Trans>
                            <a href='https://sellmetime.com/' target={"_blank"}>sellmetime.com</a>
                        </p>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default Product;