import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import Header from './header';

import Home from './home/index';
import Ico from './ico/index';
import Brand from './brand/index';
import Exchange from './exchange/index';
import Team from './team/index';
import Activity from './activity/index';
import Download from './download/index';
import Webtoon from './webtoon';

import Footer from './footer';

const Root = () => {
    const getLocation = (useLocation().pathname).split('/')
    if(getLocation[1]) localStorage.lang = getLocation[1]
    else if(navigator.language || navigator.userLanguage) localStorage.lang = navigator.language || navigator.userLanguage
    const [, i18n] = useTranslation()
    i18n.changeLanguage(localStorage.lang)

    if(getLocation[2]) {
        // if(getLocation[2] === 'ico') return (<><Ico/></>)
        // if(getLocation[2] === 'ico') return (<><Ico/><ExtraBonus/></>)
        // else if(getLocation[2] === 'about') return (<><Baduk/><Basick/><Ceo/></>)
        // else if(getLocation[2] === 'economy') return (<Economy/>)
        // else if(getLocation[2] === 'roadmap') return (<RoadMap/>)
        // else if(getLocation[2] === 'service') return (<Product/>)
        // else if(getLocation[2] === 'exchange_Purchase') return (<Purchase/>)
        // if(getLocation[2] === 'service') return (<Product/>)
        // else if(getLocation[2] === 'download') return (<Download/>)
        // else if(getLocation[2] === 'webtoon') return (<Webtoon/>)
        // else 
        return (
            <>
                <Home/>
                <Ico />
                <Brand />
                <Exchange />
                <Team />
                <Activity />
                <Download />
                <Webtoon/>
            </>
        )
    } else {
        return (
            <>
                <Header/>
                <Home/>
                <Ico />
                <Brand />
                <Exchange />
                <Team />
                <Activity/>
                <Download />
                <Webtoon/>
                <Footer/>  
            </>
        );
    }
    
};

export default Root;