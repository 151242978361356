import React from 'react';

function Footer() {
	return (
		<>
			<div id="footer">
				<div className="footerTab">
					<div className="companyInfo">
						<p>Mojaik © 2022</p>
						<p>Harbros CO., LTD.</p>
						{/* <p>Blood LTD. License No. 171579</p> */}
						{/* <p>INCORPORATED THE 21th Day of August 2018, Belize City, Belize</p> */}
						<p>Email : info@mojaik.com / mojaik.com@gmail.com</p>
					</div>
					<div className='social'>
						<a href='https://t.me/timestope1010' target={"_blank"}><img src={require('../img/telegram.png')} /></a>
						<a href='https://www.facebook.com/MojaikOfficial?mibextid=ZbWKwL' target={"_blank"}><img src={require('../img/facebook.png')} /></a>
						<a href='https://youtube.com/@Timestope?si=borJcZVG1OLlWIAn' target={"_blank"}><img src={require('../img/youtube.png')} /></a>
						<a href='https://x.com/mojaik_official?s=09' target={"_blank"}><img src={require('../img/twitter.png')} /></a>
						<a href='https://instagram.com/mojaik_official?igshid=MzMyNGUyNmU2YQ==' target={"_blank"}><img src={require('../img/instagram.png')} /></a>
						<a href='https://www.linkedin.com/company/mojaik-platform' target={"_blank"}><img src={require('../img/linkedin.png')} /></a>
						<a href='https://cafe.naver.com/blood2018' target={"_blank"}><img src={require('../img/cafe.png')} /></a>
						<a href='https://play.google.com/store/apps/details?id=com.timestope' target={"_blank"}><img src={require('../img/playstore.png')} /></a>
						<a href='https://discord.gg/YqM7gFzEXF' target={"_blank"}><img src={require('../img/discord.png')} /></a>
						{/* <a href='https://open.kakao.com/o/gurMVBGd' target={"_blank"}><img src={require('../img/kakao.png')} /></a> */}
					</div>
				</div>
			</div>
		</>
	);
}

export default Footer;