import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next'
import $ from 'jquery';
const moment = require("moment");

const Staking = () => {
    const [t] = useTranslation();
    const openTimer = 1713841200000;

    const changeSubTitle = (side) => {
        $('.stakingSub>div').css('color', '#DC3545')
        $('.stakingSub>div').css('background', 'white')
        $('.stakingText').hide()
        const mergeClass = '.stakingSub_'+ side
        $(mergeClass).css('color', 'white')
        $(mergeClass).css('background', '#DC3545')
        if(moment.utc().valueOf() < openTimer) {
            $('#openningText').show()
        } else {
            if(side == 'right') {
                $('#unDelegationText').show()
            } else {
                $('#delegationText').show()
            }
        }
    }
    useEffect(() => {
        $('.stakingText').hide()
        if(moment.utc().valueOf() < openTimer) {
            $('#openningText').show()
        } else {
            $('#delegationText').show()
        }
    },[])

    return (
        <>
            <div id="exchange_Staking" className='exchangePart content'>
                <p className='fsMT'>{t('purchase_staking_Title')}</p>

                <div className='targetAddress subTitle stakingSub'>
                    <div className='stakingSub_left' onClick={() => changeSubTitle('left')}>Delegation</div>
                    <div className='stakingSub_right' onClick={() => changeSubTitle('right')}>unDelegation</div>
                </div>

                <table className='stakingName'>
                    <thead>
                    </thead>
                    <tbody id="openningText" className='stakingText'>
                        <tr>
                            <td className="fsNT">Staking will open on April 24, 2024</td>
                        </tr>
                    </tbody>
                    <tbody id='unDelegationText' className='stakingText'>
                        <tr>
                            <td colSpan={3}>
                            <div className='explanationImg'>
                                <img src={require('../../img/unstaking1.png')} loading="lazy"/>
                                <img src={require('../../img/unstaking2.png')} loading="lazy"/>
                                <img src={require('../../img/unstaking3.png')} loading="lazy"/>
                                <img src={require('../../img/unstaking4.png')} loading="lazy"/>
                                <img src={require('../../img/unstaking5.png')} loading="lazy"/>
                                <img src={require('../../img/unstaking6.png')} loading="lazy"/>
                            </div>
                            </td>
                        </tr>                       
                    </tbody>
                    <tbody id='delegationText' className='stakingText'>
                        <tr>
                            <td colSpan={3}>
                            <div className='explanationImg'>
                                <img src={require('../../img/staking1.png')} loading="lazy"/>
                                <img src={require('../../img/staking2.png')} loading="lazy"/>
                                <img src={require('../../img/staking3.png')} loading="lazy"/>
                                <img src={require('../../img/staking4.png')} loading="lazy"/>
                                <img src={require('../../img/staking5.png')} loading="lazy"/>
                                <img src={require('../../img/staking6.png')} loading="lazy"/>
                            </div>
                            </td>
                        </tr>                                            
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Staking;