import { useTranslation, Trans } from 'react-i18next'

const Basick = () => {
	const [t] = useTranslation();
	return (
		<>
			<div id='basick' className='content'>
			<p className='fsST'>{t('basick_MT')}</p>
			<div className='collaborationContent'>
				<div>
					<iframe src="https://www.youtube.com/embed/lrrVtepyEcM?controls=0&modestbranding=1&disablekb=1" loading="lazy"></iframe>
				</div>
				<div className='collaboration'>
					<img className='bgImg' src={require('../../img/basickBg.png')} loading="lazy"/>
					<img className='basickImg' src={require('../../img/basick.png')} loading="lazy"/>
					<p><Trans>basick_Content</Trans>
					</p>
				</div>
			</div>
			</div>
		</>
	);
};

export default Basick;