import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import TranslationEn from "./en.json";
import TranslationKo from "./ko.json";
import TranslationVi from "./vi.json";
import TranslationZh_S from "./zh-s.json";
import TranslationZh_T from "./zh-t.json";

const resource = {
    en: {
        translation: TranslationEn
    },
    ko: {
        translation: TranslationKo
    },
    vi: {
        translation: TranslationVi
    },
    zh_s: {
        translation: TranslationZh_S
    },
    zh_t: {
        translation: TranslationZh_T
    }
}

i18n.use(initReactI18next).init({
    resources:resource,
    lng: "en",
    fallbackLng: "en",
    debug: false,
    interpolation: {
        escapeValue: false
    }
})


export default i18n;