import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next'
import $ from 'jquery';
const moment = require("moment");

const Swap = () => {
    const swapWalletAddress = "0xba9E8D30A80BD2863c2e795635edDc5dCE2495F8"
    const [t] = useTranslation();
    const [swapContent, setSwapContent] = useState('');

    const copyText = (path = false, text = '') => {
        $('.networkName a').css('color', '#4080FF')
        $('.copyAddress').css('background', '#DC3545')
        if(path) $('.copyAddress').css('background', 'darkslateblue')
        navigator.clipboard.writeText(text)
    }
    const changeSubTitle = (side) => {
        $('.swapSub>div').css('color', '#DC3545')
        $('.swapSub>div').css('background', 'white')
        $('.swapAddress').css('background', '#DC3545')
        const mergeClass = '.swapSub_'+ side
        $(mergeClass).css('color', 'white')
        $(mergeClass).css('background', '#DC3545')
        if(side == 'right') {
            setSwapContent(<Trans>purchase_swapContentText_Token</Trans>)
            $('.onType').text('Mojaik FEE Coin')
            $('.onNetwork').text('Mojaik Babylon(Shard 0)')
        } else {
            setSwapContent(<Trans>purchase_swapContentText_Coin</Trans>)
            $('.onType').text('Mojaik FEE Token')
            $('.onNetwork').text('Polygon')
        }
    }

    useEffect(() => {
        setSwapContent(<Trans>purchase_swapContentText_Coin</Trans>)
    },[])

    return (
        <>
            <div id="exchange_Swap" className='exchangePart content'>
                <div className='intro'>
                    <p  className='fsMT'>{t('purchase_swap_Title')}</p>

                    <div className='targetAddress subTitle swapSub'>
                        <div className='swapSub_left' onClick={() => changeSubTitle('left')}>Mojaik FEE Coin</div>
                        <div className='swapSub_right' onClick={() => changeSubTitle('right')}>Mojaik FEE Token</div>
                    </div>

                    <div className='targetAddress red'>
                        <div className='walletForm'>
                            <p>{swapWalletAddress}</p>
                            <a className="copyAddress swapAddress" onClick={() => copyText(true, swapWalletAddress)}>Copy</a>
                        </div>
                    </div>
                    <p className='fsNT'>
                        <Trans>purchase_swapContent_Pre</Trans>
                        <span className='red onType'>Mojaik FEE Token</span>
                        <Trans>purchase_swapContent_Suf</Trans>
                        <span className='red onNetwork'>Polygon</span>
                        <Trans>purchase_swapContent_Sub</Trans>
                    </p>
                    <p className='explanationText'>{swapContent}</p>
                </div>
            </div>
        </>
    );
};

export default Swap;