import Ico_first from './ico_first';
import Ico_second from './ico_second';
import ExtraBonus from './extraBonus';

const Ico = () => {
	return (
        <>
        {/* <Ico_first /> */}
		{/* <Ico_second /> */}
		{/* <ExtraBonus /> */}
        </>
	);
};

export default Ico;