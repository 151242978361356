import { useTranslation } from 'react-i18next'
import $ from 'jquery';

const Download = () => {
    const [t] = useTranslation();
    const showPreviousVersions = (type) => {
        const hided = $('.showVersionList'+type).is(":hidden")
        $('.showVersionList').hide()
        $('.showVersion').text(t('download_ShowOldVersion'))
        if(hided) {
            $('.showVersionList'+type).css('display', 'flex')
            $('.showVersion'+type).text(t('download_HideOldVersion'))
        }
    }

    return (
        <>
            <div id='download' className='content'>
                <p className='fsMT'><img src={require('../../img/downloadIcon.png')} loading="lazy"/>Download</p>
                <div className='downloadItem'>
                    <div className='downloadItemDetail'>
                        <p>{t('download_Whitepaper')}</p>
                        <div className='downloadItemLink'>
                            <img src={require('../../img/whitepaperIcon.png')} />
                            {/* http://63.250.52.222:62000/4689a970bb3d4a8f622a01b4f6028121/mojaik-wallet/downloadFile/ */}
                            <div className='downloadList'>                     
                                <a href='https://github.com/Timestopeofficial/Mojaik/blob/main/Document/Mojaik_1_8(English).pdf' download="Mojaik_1_8(English).pdf" target={"_blank"}>{t('download_Whitepaper')} 1.8(EN)<img src={require('../../img/download_small.png')} /></a>
                                <a href='https://github.com/Timestopeofficial/Mojaik/blob/main/Document/Mojaik_1_8(Korean).pdf' download="Mojaik_1_8(Korean).pdf" target={"_blank"}>{t('download_Whitepaper')} 1.8(KR)<img src={require('../../img/download_small.png')} /></a>
                                <a href='https://github.com/Timestopeofficial/Mojaik/blob/main/Document/Mojaik_1_8(Chinese-Simplied).pdf' download="Mojaik_1_8(Chinese-Simplied).pdf" target={"_blank"}>{t('download_Whitepaper')} 1.8(ZH-S)<img src={require('../../img/download_small.png')} /></a>
                                <a href='https://github.com/Timestopeofficial/Mojaik/blob/main/Document/Mojaik_1_8(Chinese-Traditional).pdf' download="Mojaik_1_8(Chinese-Traditional).pdf" target={"_blank"}>{t('download_Whitepaper')} 1.8(ZH-T)<img src={require('../../img/download_small.png')} /></a>
                                <a href='https://github.com/Timestopeofficial/Mojaik/blob/main/Document/Mojaik_1_8(Vietnam).pdf' download="Mojaik_1_8(Vietnamese).pdf" target={"_blank"}>{t('download_Whitepaper')} 1.8(VN)<img src={require('../../img/download_small.png')} /></a>
                                {/* <p className='showVersion showVersionWhitepaper' onClick={() => { showPreviousVersions('Whitepaper') }}>{t('download_ShowOldVersion')}</p> */}
                                {/* <div className='showVersionList showVersionListWhitepaper'>
                                    <a href='/Mojaik_1_0(English).pdf' download="Mojaik_1_0(English).pdf" target={"_blank"}>{t('download_Whitepaper')} 1.0(EN)<img src={require('../../img/download_small.png')} /></a>
                                    <a href='/Mojaik_1_0(Korean).pdf' download="Mojaik_1_0(Korean).pdf" target={"_blank"}>{t('download_Whitepaper')} 1.0(KR)<img src={require('../../img/download_small.png')} /></a>
                                    <hr/>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className='downloadItemDetail'>
                        <p>{t('download_Wallet')}</p>
                        <div className='downloadItemLink'>
                            <img src={require('../../img/walletIcon.png')} />
                            <div className='downloadList'>
                                <a href='/mojaikwallet_chrome_2_0_0.zip' target={"_blank"}>{t('download_Wallet_Chrome')} 2.0.0<img src={require('../../img/download_small.png')} /></a>
                                <a href='/mojaikwallet_windows_2_0_0.zip' target={"_blank"}>{t('download_Wallet_WindowsPortable')} 2.0.0<img src={require('../../img/download_small.png')} /></a>
                                <a href='/mojaikwallet_windows_install_1_0_6_3.exe' target={"_blank"}>{t('download_Wallet_WindowsInstall')} 1.0.6.3<img src={require('../../img/download_small.png')} /></a>
                                <p className='showVersion showVersionMojaikwallet' onClick={() => { showPreviousVersions('Mojaikwallet') }}>{t('download_ShowOldVersion')}</p>
                                
                                <div className='showVersionList showVersionListMojaikwallet'>
                                    <a href='/mojaikwallet_chrome_1_0_4.zip' target={"_blank"}>{t('download_Wallet_Chrome')} 1.0.4<img src={require('../../img/download_small.png')} /></a>
                                    <a href={'/mojaikwallet_windows_1_0_4.zip'} target={"_blank"}>{t('download_Wallet_WindowsPortable')} 1.0.4<img src={require('../../img/download_small.png')} /></a>
                                    <a href='/mojaikwallet_windows_install_1_0_4.exe' target={"_blank"}>{t('download_Wallet_WindowsInstall')} 1.0.4<img src={require('../../img/download_small.png')} /></a>
                                    <hr/>
                                    <a href='/mojaikwallet_chrome_1_0_4_2.zip' target={"_blank"}>{t('download_Wallet_Chrome')} 1.0.4.2<img src={require('../../img/download_small.png')} /></a>
                                    <a href={'/mojaikwallet_windows_1_0_4_2.zip'} target={"_blank"}>{t('download_Wallet_WindowsPortable')} 1.0.4.2<img src={require('../../img/download_small.png')} /></a>
                                    <a href='/mojaikwallet_windows_install_1_0_4_2.exe' target={"_blank"}>{t('download_Wallet_WindowsInstall')} 1.0.4.2<img src={require('../../img/download_small.png')} /></a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className='downloadItemDetail'>
                        <p>{t('download_Archive')}</p>
                        <div className='downloadItemLink'>
                            <img src={require('../../img/archiveIcon.png')} />
                            <div>
                                <a href='/Archive(English).pdf' download="Archive(English).pdf" target={"_blank"}>{t('download_Archive_Founder')}(EN)<img src={require('../../img/download_small.png')} /></a>
                                <a href='/Archive(Korean).pdf' download="Archive(Korean).pdf" target={"_blank"}>{t('download_Archive_Founder')}(KR)<img src={require('../../img/download_small.png')} /></a>
                            </div>
                            <div>
                                <a href='/CurrencyM(English).pdf' download="CurrencyM(English).pdf" target={"_blank"}>CurrencyM 1.0(EN)<img src={require('../../img/download_small.png')} /></a>
                                <a href='/CurrencyM(Korean).pdf' download="CurrencyM(Korean).pdf" target={"_blank"}>CurrencyM 1.0(KR)<img src={require('../../img/download_small.png')} /></a>
                                <a href='/CurrencyM(Chinese-Simplied).pdf' download="CurrencyM(Chinese-Simplied).pdf" target={"_blank"}>CurrencyM 1.0(ZH-S)<img src={require('../../img/download_small.png')} /></a>
                                <a href='/CurrencyM(Chinese-Traditional).pdf' download="CurrencyM(Chinese-Traditional).pdf" target={"_blank"}>CurrencyM 1.0(ZH-T)<img src={require('../../img/download_small.png')} /></a>
                                <a href='/CurrencyM(Vietnamese).pdf' download="CurrencyM(Vietnamese).pdf" target={"_blank"}>CurrencyM 1.0(VN)<img src={require('../../img/download_small.png')} /></a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default Download;