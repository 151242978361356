import Economy from './economy';
import RoadMap from './roadMap';
import Product from './product';

const Brand = () => {
	return (
        <>
		<Economy />
		{/* <RoadMap /> */}
        <Product />
        </>
	);
};

export default Brand;